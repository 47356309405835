import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { get, del, post, put } from "@/lib/rest";
import { toast } from "../../components/ui/use-toast";
import { IWorkflow, IWorkflowCredential, CredentialType } from "@/interface/workflow";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { 
  Instagram, Facebook, Twitter, Linkedin, MessageSquare, Gamepad2, Mail,
  Cloud, Database, Key, CreditCard, FolderOpen, Server, MessageCircle,
  Building2, ClipboardList, Settings, LucideIcon
} from "lucide-react";
import { CredentialDialog } from "./CredentialDialog";

type CredentialIconMap = {
  [K in CredentialType]: LucideIcon;
};

const CREDENTIAL_ICONS: Partial<CredentialIconMap> = {
  [CredentialType.INSTAGRAM]: Instagram,
  [CredentialType.FACEBOOK]: Facebook,
  [CredentialType.TWITTER]: Twitter,
  [CredentialType.LINKEDIN]: Linkedin,
  [CredentialType.SLACK]: MessageSquare,
  [CredentialType.DISCORD]: Gamepad2,
  [CredentialType.EMAIL_SMTP]: Mail,
  [CredentialType.AWS]: Cloud,
  [CredentialType.GOOGLE_CLOUD]: Cloud,
  [CredentialType.AZURE]: Cloud,
  [CredentialType.OAUTH2]: Key,
  [CredentialType.API_KEY]: Key,
  [CredentialType.BASIC_AUTH]: Key,
  [CredentialType.JWT]: Key,
  [CredentialType.STRIPE]: CreditCard,
  [CredentialType.PAYPAL]: CreditCard,
  [CredentialType.S3]: FolderOpen,
  [CredentialType.FTP]: FolderOpen,
  [CredentialType.SFTP]: FolderOpen,
  [CredentialType.POSTGRES]: Database,
  [CredentialType.MYSQL]: Database,
  [CredentialType.MONGODB]: Database,
  [CredentialType.TWILIO]: MessageCircle,
  [CredentialType.SENDGRID]: MessageCircle,
  [CredentialType.MAILCHIMP]: MessageCircle,
  [CredentialType.SALESFORCE]: Building2,
  [CredentialType.HUBSPOT]: Building2,
  [CredentialType.JIRA]: ClipboardList,
  [CredentialType.ASANA]: ClipboardList,
  [CredentialType.TRELLO]: ClipboardList,
  [CredentialType.CUSTOM]: Settings,
  [CredentialType.API_CREDENTIAL]: Settings,
};

const CREDENTIAL_CATEGORIES = {
  "Social Media & Communication": [
    CredentialType.INSTAGRAM,
    CredentialType.FACEBOOK,
    CredentialType.TWITTER,
    CredentialType.LINKEDIN,
    CredentialType.SLACK,
    CredentialType.DISCORD,
    CredentialType.EMAIL_SMTP,
  ],
  "Cloud Services": [
    CredentialType.AWS,
    CredentialType.GOOGLE_CLOUD,
    CredentialType.AZURE,
  ],
  "Authentication": [
    CredentialType.OAUTH2,
    CredentialType.API_KEY,
    CredentialType.BASIC_AUTH,
    CredentialType.JWT,
  ],
  "Payment": [
    CredentialType.STRIPE,
    CredentialType.PAYPAL,
  ],
  "Storage": [
    CredentialType.S3,
    CredentialType.FTP,
    CredentialType.SFTP,
  ],
  "Database": [
    CredentialType.POSTGRES,
    CredentialType.MYSQL,
    CredentialType.MONGODB,
  ],
  "Messaging": [
    CredentialType.TWILIO,
    CredentialType.SENDGRID,
    CredentialType.MAILCHIMP,
  ],
  "CRM": [
    CredentialType.SALESFORCE,
    CredentialType.HUBSPOT,
  ],
  "Project Management": [
    CredentialType.JIRA,
    CredentialType.ASANA,
    CredentialType.TRELLO,
  ],
  "Generic/Custom": [
    CredentialType.CUSTOM,
    CredentialType.API_CREDENTIAL,
  ],
};

export default function Workflow() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
  const [credentials, setCredentials] = useState<IWorkflowCredential[]>([]);
  const [isCredentialDialogOpen, setIsCredentialDialogOpen] = useState(false);
  const [newCredential, setNewCredential] = useState<Partial<IWorkflowCredential>>({
    name: '',
    type: undefined,
    data: {}
  });
  const [editingCredential, setEditingCredential] = useState<IWorkflowCredential | null>(null);

  useEffect(() => {
    fetchWorkflows();
    fetchCredentials();
  }, []);

  const fetchWorkflows = async () => {
    try {
      const response = await get({ url: `/api/project/${projectId}/workflow` });
      const fetchedWorkflows = response?.data?.workflows?.map(
        (workflow: IWorkflow) => ({
          ...workflow
        }),
      );
      setWorkflows(fetchedWorkflows || []);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error fetching workflows",
        description: "There was an error fetching workflows",
      });
    }
  };

  const handleDelete = async (workflowId: string) => {
    try {
      await del({
        url: `/api/project/${projectId}/workflow/${workflowId}`,
        data: {},
      });
      fetchWorkflows();
      toast({
        title: "Workflow deleted successfully",
        description: "The workflow has been deleted successfully",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error deleting workflow",
        description: "There was an error deleting the workflow",
      });
    }
  };

  const createNewWorkflow = () => {
    navigate(`/project/${projectId}/workflow/edit`);
  };

  const fetchCredentials = async () => {
    try {
      const response = await get({ url: `/api/project/${projectId}/credential` });
      setCredentials(response?.data?.credentials || []);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error fetching credentials",
      });
    }
  };

  const handleCredentialDelete = async (credentialId: string) => {
    try {
      await del({
        url: `/api/project/${projectId}/credential/${credentialId}`,
        data: {},
      });
      fetchCredentials();
      toast({
        title: "Credential deleted successfully",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error deleting credential",
      });
    }
  };

  const handleCredentialEdit = (credential: IWorkflowCredential) => {
    setEditingCredential(credential);
    setIsCredentialDialogOpen(true);
  };

  const handleCredentialSave = async () => {
    try {
      const isEditing = !!editingCredential;
      const url = isEditing 
        ? `/api/project/${projectId}/credential/${editingCredential.id}`
        : `/api/project/${projectId}/credential`;

      if (isEditing) {
        await put({ url, data: editingCredential });
      } else {
        await post({ url, data: newCredential });
      }
      
      setIsCredentialDialogOpen(false);
      setEditingCredential(null);
      setNewCredential({ name: '', type: undefined, data: {} });
      fetchCredentials();
      
      toast({
        title: `Credential ${isEditing ? 'updated' : 'saved'} successfully`,
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: `Error ${editingCredential ? 'updating' : 'saving'} credential`,
      });
    }
  };

  return (
    <div className="h-full">
      <div className="container mx-auto p-4">
        <Tabs defaultValue="workflows">
          <TabsList>
            <TabsTrigger value="workflows">Workflows</TabsTrigger>
            <TabsTrigger value="credentials">Credentials</TabsTrigger>
          </TabsList>

          <TabsContent value="workflows">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-2xl font-bold">Workflows</h2>
              <Button onClick={createNewWorkflow} className="flex items-center gap-2">
                <Plus className="h-4 w-4" />
                Create Workflow
              </Button>
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-left">Name</TableHead>
                  <TableHead className="text-left">Active</TableHead>
                  <TableHead className="text-left">Created At</TableHead>
                  <TableHead className="text-left">Last Updated</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {workflows.map((workflow) => (
                  <TableRow
                    key={workflow.id}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/project/${projectId}/workflow/edit/${workflow.id}`)
                    }
                  >
                    <TableCell className="text-left">{workflow.name}</TableCell>
                    <TableCell className="text-left">{workflow.active ? "Yes" : "No"}</TableCell>
                    <TableCell className="text-left">
                      {new Date(workflow.created_at || "").toLocaleDateString()}
                    </TableCell>
                    <TableCell className="text-left">
                      {new Date(workflow.updated_at || "").toLocaleDateString()}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(workflow?.id || "");
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabsContent>

          <TabsContent value="credentials">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-2xl font-bold">Credentials</h2>
              <Dialog 
                open={isCredentialDialogOpen} 
                onOpenChange={(open) => {
                  setIsCredentialDialogOpen(open);
                  if (!open) {
                    setEditingCredential(null);
                    setNewCredential({ name: '', type: undefined, data: {} });
                  }
                }}
              >
                <DialogTrigger asChild>
                  <Button className="flex items-center gap-2">
                    <Plus className="h-4 w-4" />
                    Add Credential
                  </Button>
                </DialogTrigger>
                <CredentialDialog 
                  credential={editingCredential || newCredential}
                  onCredentialChange={(cred) => {
                    if (editingCredential) {
                      setEditingCredential(cred as IWorkflowCredential);
                    } else {
                      setNewCredential(cred);
                    }
                  }}
                  onSave={handleCredentialSave}
                  mode={editingCredential ? 'edit' : 'create'}
                />
              </Dialog>
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-left">Name</TableHead>
                  <TableHead className="text-left">Type</TableHead>
                  <TableHead className="text-left">Created At</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {credentials.map((credential) => (
                  <TableRow 
                    key={credential.id} 
                    className="cursor-pointer"
                    onClick={() => handleCredentialEdit(credential)}
                  >
                    <TableCell className="text-left">{credential.name}</TableCell>
                    <TableCell className="text-left">
                      <div className="flex items-center gap-2">
                        {CREDENTIAL_ICONS[credential.type] && 
                          React.createElement(CREDENTIAL_ICONS[credential.type]!, { size: 16 })}
                        <span>{credential.type}</span>
                      </div>
                    </TableCell>
                    <TableCell className="text-left">
                      {new Date(credential.created_at || "").toLocaleDateString()}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCredentialDelete(credential.id);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
