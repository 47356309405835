import React, { memo } from 'react';
import { Handle, Position, Node } from '@xyflow/react';
import { IWorkflowNodeDefinition } from '@/interface/workflow';
import { useWorkflowExecutionStore } from '@/lib/store';
import ResultViewer from './ResultViewer';

type ICustomNodeProps = Node<{
  definition: IWorkflowNodeDefinition;
  label: string;
}>

const CustomNode = memo(({ data, id }: ICustomNodeProps) => {
  const { definition } = data;
  const { connection_config } = definition;
  
  const nodeResults = useWorkflowExecutionStore((state) => {
    const workflowId = window.location.pathname.split('/').pop() || '';
    return state.results[workflowId]?.[data.label];
  });

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevents React Flow from capturing scroll events
  };

  return (
    <>
      {/* Input handles descriptions - outside the node */}
      <div className="absolute left-0 top-0 flex flex-col gap-4 -translate-x-[calc(100%+8px)] h-[64px] justify-center">
        {connection_config.inputs.types.map((type: string) => (
          <div key={`input-label-${type}`} className="text-xs text-gray-500">
            {type}
          </div>
        ))}
      </div>

      <div className="flex flex-col">
        {/* Main node body with fixed height */}
        <div className="shadow-md rounded-md bg-white border-2 border-gray-200 min-w-[320px] h-[64px] relative">
          {/* Input handles only */}
          <div className="absolute left-0 top-0 flex flex-col gap-4 h-full justify-center">
            {connection_config.inputs.types.map((type: string) => (
              <Handle
                key={`input-${type}`}
                type="target"
                position={Position.Left}
                id={type}
                style={{ 
                  background: '#555',
                  width: 8,
                  height: 8
                }}
              />
            ))}
          </div>

          {/* Node content */}
          <div className="px-4 py-2">
            <div className="font-bold">{data.label}</div>
          </div>

          {/* Output handles only */}
          <div className="absolute right-0 top-0 flex flex-col gap-4 h-full justify-center">
            {connection_config.outputs.types.map((type: string) => (
              <Handle
                key={`output-${type}`}
                type="source"
                position={Position.Right}
                id={type}
                style={{ 
                  background: '#555',
                  width: 8,
                  height: 8
                }}
              />
            ))}
          </div>
        </div>

        {/* Results section below the main node */}
        {nodeResults && (
          <div onWheel={handleScroll}  className="mt-2 shadow-md rounded-md bg-white border-2 border-gray-200 min-w-[320px]">
            <div className="px-4 py-2">
              <div className="font-semibold text-xs text-gray-600 mb-1">Result:</div>
              <div className="overflow-hidden rounded border border-gray-200">
                <ResultViewer value={nodeResults} />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Output handles descriptions - outside the node */}
      <div className="absolute right-0 top-0 flex flex-col gap-4 translate-x-[calc(100%+8px)] h-[64px] justify-center">
        {connection_config.outputs.types.map((type: string) => (
          <div key={`output-label-${type}`} className="text-xs text-gray-500">
            {type}
          </div>
        ))}
      </div>
    </>
  );
});

export default CustomNode; 