import React, { useState } from 'react';
import { IWorkflowNode, SchemaProperty, UIComponentType } from '../../interface/workflow';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { InputField } from "./input-fields/InputField";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";

interface ConfigPanelProps {
  selectedNode: IWorkflowNode | null;
  onUpdateParameters: (nodeId: string, key: string, value: any) => void;
  onUpdateLabel: (nodeId: string, newLabel: string) => void;
  onDelete: (nodeId: string) => void;
  onUpdateCredentials: (nodeId: string, key: string, value: any) => void;
}

const ConfigPanel: React.FC<ConfigPanelProps> = ({
  selectedNode,
  onUpdateParameters,
  onUpdateLabel,
  onDelete,
  onUpdateCredentials,
}) => {
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});

  if (!selectedNode) {
    return <div className="w-80 border-l p-4">Select a node to configure</div>;
  }

  const inputSchemas = selectedNode.data.definition?.input_schemas || {};

  const toggleSection = (key: string) => {
    setOpenSections(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className="w-80 border-l p-4">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Node Label</label>
          <Input
            value={selectedNode.data.label || ''}
            onChange={(e) => onUpdateLabel(selectedNode.id, e.target.value)}
            className="w-full"
          />
        </div>
        
        <div className="space-y-2">
          {Object.entries(inputSchemas).map(([schemaKey, schema]) => (
            <div 
              key={schemaKey} 
              className="pb-2 border-b"
            >
              <button
                onClick={() => toggleSection(schemaKey)}
                className="flex items-center w-full py-2 text-sm hover:bg-gray-50 rounded-sm transition-colors"
              >
                <ChevronDown 
                  className={cn(
                    "h-4 w-4 transition-transform duration-200",
                    openSections[schemaKey] ? "transform rotate-180" : ""
                  )}
                />
                <span className="capitalize flex-1 text-center font-medium">
                  {schemaKey === 'default' ? 'General' : schemaKey}
                </span>
                <div className="w-4" />
              </button>
              {openSections[schemaKey] && (
                <div className="space-y-4 pt-2">
                  {Object.entries(schema.properties || {})
                    .sort(([, a], [, b]) => {
                      const orderA = (a as SchemaProperty).order ?? Infinity;
                      const orderB = (b as SchemaProperty).order ?? Infinity;
                      return orderA - orderB;
                    })
                    .map(([key, propertySchema]: [string, any]) => {
                      const currentValue = propertySchema.ui?.component === UIComponentType.CREDENTIAL_SELECTOR
                        ? selectedNode.data.credentials?.[key] ?? propertySchema.default ?? ""
                        : selectedNode.data.parameters?.[key] ?? propertySchema.default ?? "";
                      return (
                        <InputField
                          key={key}
                          label={key}
                          value={currentValue}
                          onChange={(value) => propertySchema.ui?.component === UIComponentType.CREDENTIAL_SELECTOR 
                            ? onUpdateCredentials(selectedNode.id, key, value)
                            : onUpdateParameters(selectedNode.id, key, value)}
                          schema={propertySchema}
                          node={selectedNode}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        
        <div className="pt-4 border-t">
          <Button
            variant="destructive"
            onClick={() => onDelete(selectedNode.id)}
            className="w-full"
          >
            Delete Node
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfigPanel; 