export interface IWorkflowNode {
    id: string;
    type: string;
    position: {
        x: number;
        y: number;
    };
    data: {
        label: string;
        definition: IWorkflowNodeDefinition;
        parameters: Record<string, SchemaPropertyValue>;
        credentials?: Record<string, IWorkflowCredential>;
    };
    settings?: Record<string, SchemaPropertyValue>;
}

export interface IWorkflowConnection {
    id: string;
    source: string;
    target: string;
    sourceHandle?: string;
    targetHandle?: string;
}

export interface IWorkflowSettings {
    errorHandling?: {
        retryCount?: number;
        retryDelay?: number;
        failureAction?: 'continue' | 'stop';
    };
    execution?: {
        timeout?: number;
        concurrency?: number;
    };
}

export interface IWorkflow {
    id?: string;
    name: string;
    active: boolean;
    nodes: IWorkflowNode[];
    connections: IWorkflowConnection[];
    settings: IWorkflowSettings;
    project_id?: string;
    environment_id?: string;
    created_at?: Date;
    updated_at?: Date;
}

export enum UIComponentType {
    // Input Components
    TEXT_INPUT = "text-input",
    NUMBER_INPUT = "number-input",
    SELECT = "select",
    MULTISELECT = "multiselect",
    CHECKBOX = "checkbox",
    RADIO = "radio",
    SWITCH = "switch",

    // Complex Input Components
    CODE_EDITOR = "code-editor",
    JSON_EDITOR = "json-editor",
    KEY_VALUE_EDITOR = "key-value-editor",
    FILE_UPLOAD = "file-upload",
    DATE_PICKER = "date-picker",
    TIME_PICKER = "time-picker",
    COLOR_PICKER = "color-picker",

    // Layout Components
    FORM_GROUP = "form-group",
    TABS = "tabs",
    ACCORDION = "accordion",

    // Custom Components
    CREDENTIAL_SELECTOR = "credential-selector",
    ENVIRONMENT_SELECTOR = "environment-selector",
    API_ENDPOINT_SELECTOR = "api-endpoint-selector"
}

// Also add a UI configuration type
export interface UIConfig {
    component: UIComponentType;
    placeholder?: string;
    label?: string;
    description?: string;
    height?: string;
    width?: string;
    options?: Array<{ value: string | number; label: string }> | string[] | number[];
    min?: number;
    max?: number;
    step?: number;
    unit?: string;
    language?: string;
    addButtonText?: string;
    disabled?: boolean;
    required?: boolean;
    default?: any;
    credential_type?: CredentialType;
}

// Update the schema property interface to include UI config
export interface SchemaProperty {
    type: string;
    description?: string;
    order?: number;
    format?: string;
    enum?: any[];
    items?: any;
    properties?: Record<string, SchemaProperty>;
    required?: string[];
    default?: any;
    ui?: UIConfig;
}

export interface IWorkflowNodeDefinition {
    name: string;
    display_name: string;
    type: string;
    description: string;
    credential_required: boolean;
    database_connection_required: boolean;
    input_schemas: Record<string, JSONSchema>;
    output_schemas: Record<string, JSONSchema>;
    connection_config: {
        inputs: {
            min: number;
            max: number;
            types: string[];
        };
        outputs: {
            min: number;
            max: number;
            types: string[];
        };
    };
    ui_config?: {
        color?: string;
        icon?: string;
        category?: string;
        [key: string]: unknown;
    };
}

// Add these new interfaces to properly type the schema structures
export interface JSONSchema {
    type: string | string[];
    description?: string;
    format?: string;
    enum?: Array<string | number | boolean>;
    items?: JSONSchema;
    properties?: Record<string, SchemaProperty>;
    required?: string[];
    additionalProperties?: boolean | JSONSchema;
}

// Define possible values that can be stored in schema properties
export type SchemaPropertyValue =
    | string
    | number
    | boolean
    | null
    | SchemaPropertyValue[]
    | { [key: string]: SchemaPropertyValue };

export interface IWorkflowCredential {
    id: string;
    name: string;
    type: CredentialType;
    data: Record<string, unknown>;
    project_id: string;
    environment_id: string;
    created_at?: Date;
    updated_at?: Date;
}

export enum CredentialType {
    // Social Media & Communication
    INSTAGRAM = "instagram",
    FACEBOOK = "facebook",
    TWITTER = "twitter",
    LINKEDIN = "linkedin",
    SLACK = "slack",
    DISCORD = "discord",
    EMAIL_SMTP = "email-smtp",

    // Cloud Services
    AWS = "aws",
    GOOGLE_CLOUD = "google-cloud",
    AZURE = "azure",

    // Authentication
    OAUTH2 = "oauth2",
    API_KEY = "api-key",
    BASIC_AUTH = "basic-auth",
    JWT = "jwt",

    // Payment
    STRIPE = "stripe",
    PAYPAL = "paypal",

    // Storage
    S3 = "s3",
    FTP = "ftp",
    SFTP = "sftp",

    // Database
    POSTGRES = "postgres",
    MYSQL = "mysql",
    MONGODB = "mongodb",

    // Messaging
    TWILIO = "twilio",
    SENDGRID = "sendgrid",
    MAILCHIMP = "mailchimp",

    // CRM
    SALESFORCE = "salesforce",
    HUBSPOT = "hubspot",

    // Project Management
    JIRA = "jira",
    ASANA = "asana",
    TRELLO = "trello",

    // Generic/Custom
    CUSTOM = "custom",  // For any credential type not covered above
    API_CREDENTIAL = "api-credential"  // Generic API credential with custom fields
}
